import React, { useState } from "react";
import IconFolderOpen from "./IconFolderOpen";
import IconFolderClose from "./IconFolderClose";
import IconFile from "./IconFile";

const Folder = ({ folder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFolder = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        className="flex items-center cursor-pointer p-3 border-b hover:border hover:rounded-lg transition-all duration-300"
        onClick={toggleFolder}
      >
        <span>
          {isOpen ? (
            <IconFolderOpen clases={"w-6"} />
          ) : (
            <IconFolderClose clases={"w-6"} />
          )}
        </span>
        <span className="ml-2 uppercase text-sm text-zinc-700">
          {folder.nombre}
        </span>
      </div>
      {isOpen && (
        <div className="pl-4">
          {folder.archivos && (
            <ul className="text-zinc-700 text-sm">
              {folder.archivos.map((archivo, index) => (
                <li
                  key={index}
                  className="border-b p-3 flex items-center gap-x-1 hover:border hover:rounded-lg transition-all duration-300"
                >
                  <IconFile clases={"w-6 opacity-80"} />
                  <a href={`https://ccia-arbitraje.nyc3.digitaloceanspaces.com/sig/${archivo.url}${archivo.file}`} className="hover:underline" target="_blank" rel="noreferrer">{archivo.file}</a>
                </li>
              ))}
            </ul>
          )}
          {folder.carpetas && (
            <ul>
              {Object.keys(folder.carpetas).map((key) => (
                <li key={key}>
                  <Folder folder={folder.carpetas[key]} />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

const Explorador = ({ data }) => {
  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold uppercase text-zinc-700 mb-3">Directorio SIG</h2>
      {data.carpetas && (
        <ul>
          {Object.keys(data.carpetas).map((key) => (
            <li key={key}>
              <Folder folder={data.carpetas[key]} />
            </li>
          ))}
        </ul>
      )}
      {data.archivos && (
        <div className="mb-4">
          <ul className="text-zinc-700 text-sm">
            {data.archivos.map((file, index) => (
              <li
                key={index}
                className="border-b p-3 flex items-center gap-x-1 hover:border hover:rounded-lg transition-all duration-300"
              >
                <IconFile clases={"w-6 opacity-80"} />
                {file}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Explorador;