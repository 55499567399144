import { useEffect, useState } from "react";
import Explorador from "./components/Explorador";

function App() {
  const [files, setFiles] = useState([])

  useEffect(() => {
    populate();
  }, [])
  
  const populate = async () => {
    const path = (window.location.href.split("/").reverse()[0])
    const requestURL = "/files.json";
    const request = new Request(requestURL);
    const response = await fetch(request);
    const filesData = await response.json();
    if(path !== "" && path === "public"){
      filesData.carpetas.forEach(function(currentValue, index, arr){
        if(filesData.carpetas[index].nombre==="Consultoría"){
          filesData.carpetas.splice(index, index);     
         }
        })
    }
    setFiles(filesData);
  }

  return (
    <div className="max-w-7xl mx-auto">
      <Explorador data={files} />
    </div>
  );
}

export default App;
